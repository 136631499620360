// @flow strict
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';
import type { MarkdownRemark } from '../types';

type Props = {
  data: {
    markdownRemark: MarkdownRemark
  },
  pageContext: {
    slug: string
  }
};

const PageTemplate = ({ data, pageContext }: Props) => {
  const { slug } = pageContext;
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const { html: pageBody } = data.markdownRemark;
  const { frontmatter } = data.markdownRemark;
  const {
    tabTitle,
    pageTitle,
    description: pageDescription,
    socialImage
  } = frontmatter;
  const metaDescription = pageDescription !== null ? pageDescription : siteSubtitle;
  const isCV = (slug === '/pages/cv/');

  return (
    <Layout title={`${tabTitle} - ${siteTitle}`} description={metaDescription} socialImage={socialImage}>
      {isCV ? null : < Sidebar / >}
      <Page title={pageTitle} isCV={isCV}>
        <div dangerouslySetInnerHTML={{ __html: pageBody }} />
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        tabTitle
        pageTitle
        date
        description
        socialImage
      }
    }
  }
`;

export default PageTemplate;
