import React, { useRef, useEffect } from 'react';
import styles from './Page.module.scss';
import cvStyles from './CVPage.module.scss';

type Props = {
  title?: string,
  isCV: boolean,
  children: React.Node
};

const Page = ({ title, isCV, children }: Props) => {
  const pageRef = useRef();

  useEffect(() => {
    pageRef.current.scrollIntoView();
  });

  return isCV ? (
    <div ref={pageRef} className={cvStyles['cv']}>
      <div>
        {title && <h1>{title}</h1>}
        <div>
          <div className={cvStyles['cv__contact-info']}>
            <a href="https://hungtuchen.com/"> {'website: hungtuchen.com'} </a>
            <a href="mailto:hungtu.e.chen@gmail.com"> {'email: hungtu.e.chen@gmail.com'} </a>
          </div>
          <div className={cvStyles['cv__contact-info']}>
            <span>{'address: 6207 Moore Hall Hanover, NH 03755'}</span>
          </div>
          {children}
        </div>
      </div>
    </div>)
    : (
    <div ref={pageRef} className={styles['page']}>
      <div className={styles['page__inner']}>
        { title && <h1 className={styles['page__title']}>{title}</h1>}
        <div className={styles['page__body']}>
          {children}
        </div>
      </div>
    </div>);
};

export default Page;
